.radioButtonContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 30px 0px 10px 0px;
}
.radioButtonGroup{
    display: flex;
    margin: 20px 0px;
}

.radioButton{
    padding:10px 30px;
    border: 1px solid #B5B3C3;
    cursor: pointer;
}

.radioButton:hover{
    background-color: #407BFF;
    color: #fff;

}

.radioButtonActive{
    background-color: #407BFF;
    color: #fff;
}

.radioButtonLabel{
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.005em;
}