.SigninPage{
    height: 100vh;
    width: 100wh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.SigninCard{
    width: 500px;
    background-color:#F6F6F6;
    display: flex;
    flex-direction:column;
    padding-left: 20px;
    padding-bottom: 20px;
}

.s-b{
    width: 120px;
}