.InputFieldContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 30px 0px 10px 0px;

}
.InputFieldContainer input{
    width: 80%;
    margin: 20px 0px;
    padding: 12px;
    border-radius:5px;
    border: 1px solid #616066;
    background: transparent;
}

.InputFieldContainer label{
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.005em;

}