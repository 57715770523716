.dashboardContainer {
  display: flex;
  min-height: 100vh;
}
.dashboardLeft {
  flex: 0.15;
  color: rgb(69, 69, 212);
  font-size: 15px;

  box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
}
.slidebar {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardRight {
  flex: 0.85;
  background: #f6f8fb;
}
.logo {
  margin: 10px;
}

.dashboardIntro {
  margin: 30px 30px;
}
.model {
  position: fixed;
  top: 20px;
  left: 35%;

  box-shadow: 3px 3px rgba(27, 27, 27, 0.548);

  background-color: rgb(233, 239, 241);
}
.logopara {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  letter-spacing: 0.02em;

  color: #185adb;
}
.model2 {
  position: fixed;
  top: 50px;
  font-family: Poppins;
  left: 40%;
  width: 300px;
  text-align: center;
  padding: 10px;
  box-shadow: 3px 3px rgba(27, 27, 27, 0.548);
  font-size: 10px;
  min-height: 100px;
  border-radius: 10px;
  opacity: 1;
  color: rgb(12, 12, 12);
  background-color: rgb(233, 239, 241);
}
.img {
  width: 500px;
  height: 500px;
}
.bt {
  border: none;
}
.box {
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  position: fixed;
}
.cross2 {
  top: 21px;
  background-color: rgb(241, 245, 240);
  padding: 5px;
  font-weight: bold;
  left: 570px;
  width: 20px;
}
.table {
  width: 100%;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.10000000149011612px;
  border-collapse: collapse;
}
.tableRow:nth-child(even) {
  background-color: #f1f4ff;
}
.tableData {
  text-align: left;
  padding: 8px;
}
.tableHead {
  background-color: #e8e8e8;
}
.dashboard {
  margin: 30px 30px;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
}

@media only screen and (max-width: 700px) {
  .dashboardLeft {
    display: none;
  }
  .dashboardRight td,
  th {
    font-size: 6px;
    width: 10px;
  }
  .dashboardRight {
    margin: 0;
    padding: 0;
    font-size: 6px;
  }
  .dashboard {
    margin: 0;
    padding: 0;
    font-size: 6px;
  }
  * {
    margin: 0;
    padding: 0;
  }
  .img {
    width: 10px;
  }

  button {
    font-size: 6px;
    width: 20px;
    margin: 0;
    padding: 0;
  }
}
