.ResolveBackDrop {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
  background-color: rgba(1, 1, 1, 0.5);
}

.ResolveModal {
  height: 90vh;
  width: 80vw;
  background-color: white;
  margin: 50px auto;
  display: flex;
}

.ModalLeft {
  min-height: 100%;
  background-color: #2969e5;
  flex: 0.2;
}
.ResolveBody {
  flex: 0.8;
  padding: 10px 20px;
  position: relative;
  overflow-y: scroll;
}
.ResolveBody p {
  color: coral;

  font-family: "Courier New", Courier, monospace;
}
.radioCheck {
  margin: 10px 5px;
}
.FeedBackContainer {
  margin: 10px 150px;
  padding-top: 50px;
}
.FeedBackText {
  height: 40px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  width: 300px;
  border: 1px solid #616066;
}

.TextInput {
  width: 300px;
  margin: 20px 0px 0px 0px;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #616066;
  background: transparent;
}
.Submit {
  padding: 10px 35px;
  width: 150px;
  border: none;
  border-radius: 5px;
  background-color: #2969e5;
  color: #fff;
  cursor: pointer;
}
.Submited {
  padding: 10px 35px;
  border: none;
  width: 150px;
  border-radius: 5px;
  background-color: #71798a;
  color: #fff;
  cursor: pointer;
}

.cross {
  position: absolute;
  right: 160px;
  border: none;
  background-color: white;
  cursor: pointer;
  z-index: 100;
}
@media only screen and (max-width: 700px) {
  .Submit,
  .Submited {
    width: 40px;
    margin: 0;
    padding: 10px;
  }
  .ResolveBackDrop {
    width: 99%;
    height: 100%;

    padding: 10px;
  }
  .ResolveModal {
    width: 100%;
    margin: 10px;
    height: 100%;
  }
  .cross {
    right: 20px;
  }
  .ResolveBody {
    width: 90vw;

    margin: 0;
  }
}
